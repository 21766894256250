// src/utils/cryptoUtils.js
import CryptoJS from 'crypto-js';

// Defina sua chave secreta aqui
const SECRET_KEY = "FKXP9SYjtN9qmvRRwjcUsMJ8fWYc1PDlsDpgk7cuoEZNVupvJn";

// Função para criptografar uma string
export function encryptString(text) {
    const encrypted = CryptoJS.AES.encrypt(text, SECRET_KEY).toString();
    // Substitui caracteres que podem causar problemas em URLs ou ao ser manipulados
    return encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Remove caracteres `+`, `/` e `=`
}

// Função para descriptografar uma string
export function decryptString(ciphertext) {
    // Reverte as substituições feitas na função de criptografia
    const modifiedCiphertext = ciphertext.replace(/-/g, '+').replace(/_/g, '/');
    // Adiciona o padding '=' se necessário
    const paddingLength = (4 - (modifiedCiphertext.length % 4)) % 4;
    const paddedCiphertext = modifiedCiphertext + '='.repeat(paddingLength);

    const bytes = CryptoJS.AES.decrypt(paddedCiphertext, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
}
