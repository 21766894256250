<template>


<div class="container">
    <h1 class="mb-4">Contador de Palavras</h1>
    
    <div class="form-group">
      <label for="text">Digite o texto:</label>
      <textarea id="text" class="form-control" v-model="inputText" rows="5"></textarea>
    </div>
    
    <div class="form-group mt-3">
      <label for="word">Palavra a ser contada:</label>
      <input id="word" type="text" class="form-control" v-model="searchWord" />
    </div>

    <div class="form-group mt-3">
      <label>Diferenciação entre maiúsculas e minúsculas:</label><br>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="caseSensitiveTrue" value="true" v-model="caseSensitive">
        <label class="form-check-label" for="caseSensitiveTrue">Sim</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="caseSensitiveFalse" value="false" v-model="caseSensitive">
        <label class="form-check-label" for="caseSensitiveFalse">Não</label>
      </div>
    </div>

    <button class="btn btn-primary mt-3 center" @click="countOccurrences">Contar Palavras</button>

    <div v-if="result" class="form-group mt-4">
      <label for="result">Resultado:</label>
      <textarea id="result" class="form-control" :value="result" rows="4" readonly></textarea>
    </div>
  </div>


  </template>
  
  <script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';

  export default {
    data() {
    return {
      inputText: "",
      searchWord: "",
      caseSensitive: "false",
      result: ""
    };
  },

    mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('contador-palavra',encryptTimestamp);
    },
    methods: {
      countOccurrences() {
      if (!this.searchWord) {
        this.result = "Por favor, insira uma palavra para procurar.";
        return;
      }

      let text = this.inputText;
      let word = this.searchWord;
      let flags = this.caseSensitive === "false" ? "gi" : "g";
      let regex = new RegExp(word, flags);

      let matches = [...text.matchAll(regex)];
      let count = matches.length;

      if (count > 0) {
        let firstPos = matches[0].index;
        let lastPos = matches[matches.length - 1].index;
        this.result = `A palavra '${word}' apareceu: ${count} vezes\n` +
                      `A primeira vez na posição: ${firstPos}\n` +
                      `E a última vez na posição: ${lastPos}`;
      } else {
        this.result = `A palavra '${word}' não foi encontrada no texto.`;
      }
    }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }


  .word-counter {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.word-counter h2 {
  text-align: center;
  margin-bottom: 20px;
}

.word-counter div {
  margin-bottom: 15px;
}

.word-counter label {
  display: block;
  margin-bottom: 5px;
}

.word-counter textarea,
.word-counter input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.word-counter button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.word-counter button:hover {
  background-color: #0056b3;
}

  </style>
  