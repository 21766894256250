<template>
    <div class="container">
      <h1 class="mb-4">Informações do Navegador</h1>
      <ul class="list-group">
        <li class="list-group-item">
          <strong>Navegador:</strong> {{ browserName }}
        </li>
        <li class="list-group-item">
          <strong>Versão:</strong> {{ browserVersion }}
        </li>
        <li class="list-group-item">
          <strong>Sistema Operacional:</strong> {{ os }}
        </li>
        <li class="list-group-item">
          <strong>Idioma:</strong> {{ language }}
        </li>
        <li class="list-group-item">
          <strong>Agente do Usuário:</strong> {{ userAgent }}
        </li>
        <li class="list-group-item">
          <strong>Fuso Horário:</strong> {{ timeZone }}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';
  export default {
    data() {
      return {
        browserName: '',
        browserVersion: '',
        os: '',
        language: '',
        userAgent: '',
        timeZone:'',
      };
    },
    mounted() {
      this.getBrowserInfo();
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('meu-navegador',encryptTimestamp);
    },
    methods: {
      getBrowserInfo() {
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        const vendor = navigator.vendor;
  
        this.userAgent = userAgent;
        this.language = navigator.language;
  
        // Determinar o navegador e versão
        let browserInfo;
        if (/chrome|chromium|crios/i.test(userAgent)) {
          browserInfo = userAgent.match(/(chrome|chromium|crios)\/(\d+\.\d+)/i);
          this.browserName = 'Google Chrome';
        } else if (/firefox|fxios/i.test(userAgent)) {
          browserInfo = userAgent.match(/(firefox|fxios)\/(\d+\.\d+)/i);
          this.browserName = 'Mozilla Firefox';
        } else if (/safari/i.test(userAgent) && /apple/i.test(vendor)) {
          browserInfo = userAgent.match(/version\/(\d+\.\d+)/i);
          this.browserName = 'Apple Safari';
        } else if (/msie|trident/i.test(userAgent)) {
          browserInfo = userAgent.match(/(msie|rv):(\d+\.\d+)/i);
          this.browserName = 'Microsoft Internet Explorer';
        } else if (/edg/i.test(userAgent)) {
          browserInfo = userAgent.match(/edg\/(\d+\.\d+)/i);
          this.browserName = 'Microsoft Edge';
        } else {
          this.browserName = 'Navegador Desconhecido';
        }

        // Obter o fuso horário
        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
        this.browserVersion = browserInfo ? browserInfo[2] : 'Versão Desconhecida';
  
        // Determinar o sistema operacional
        if (/windows|win32/i.test(platform)) {
          this.os = 'Windows';
        } else if (/macintosh|macintel|macppc|mac68k/i.test(platform)) {
          this.os = 'macOS';
        } else if (/linux/i.test(platform)) {
          this.os = 'Linux';
        } else if (/android/i.test(userAgent)) {
          this.os = 'Android';
        } else if (/iphone|ipad|ipod/i.test(platform)) {
          this.os = 'iOS';
        } else {
          this.os = 'Sistema Operacional Desconhecido';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  </style>
  