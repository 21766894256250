<template>
  <div class="container mt-5">
    <h1 class="mb-4">Conversor de Texto</h1>

    <!-- Textarea para o usuário inserir o texto -->
    <div class="form-group mb-3">
      <label for="inputText">Digite o texto:</label>
      <textarea class="form-control" id="inputText" rows="5" v-model="inputText" placeholder="Escreva o texto aqui"></textarea>
    </div>

    <!-- Dropdown para escolher o tipo de conversão -->
    <div class="form-group mb-3">
      <label for="conversionType">Escolha o tipo de conversão:</label>
      <select class="form-control" id="conversionType" v-model="conversionType">
        <option value="uppercase">Tudo maiúsculo</option>
        <option value="lowercase">Tudo minúsculo</option>
        <option value="capitalize">Primeira letra de cada palavra maiúscula</option>
        <option value="sentenceCase">Primeira letra da primeira palavra de cada frase maiúscula</option>
      </select>
    </div>

    <!-- Botão para converter o texto -->
    <button class="btn btn-primary mb-3" @click="convertText">Converter Texto</button>

    <!-- Textarea para exibir o texto convertido -->
    <div v-if="convertedText">
      <label for="outputText">Texto Convertido:</label>
      <textarea class="form-control" id="outputText" rows="5" readonly v-model="convertedText"></textarea>

      <!-- Botão para copiar o texto convertido -->
      <button class="btn btn-secondary mt-3" @click="copyToClipboard">Copiar Texto</button>
    </div>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';

export default {
  data() {
    return {
      inputText: '',         // Texto inserido pelo usuário
      conversionType: 'uppercase', // Tipo de conversão selecionada
      convertedText: ''      // Texto convertido
    };
  },
  mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('conversor-maiusculas-minusculas',encryptTimestamp);
    },
  methods: {
    // Função para converter o texto com base no tipo de conversão escolhido
    convertText() {
      if (this.conversionType === 'uppercase') {
        this.convertedText = this.inputText.toUpperCase();
      } else if (this.conversionType === 'lowercase') {
        this.convertedText = this.inputText.toLowerCase();
      } else if (this.conversionType === 'capitalize') {
        this.convertedText = this.inputText.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
      } else if (this.conversionType === 'sentenceCase') {
        this.convertedText = this.inputText.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, char => char.toUpperCase());
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.convertedText).then(() => {
        Swal.fire({
            icon: 'success',
            title: 'Copiado!',
            text: 'Texto copiado para a área de transferência.',
            timer: 1000,
            showConfirmButton: false
          });
      });
    },
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.top-0 {
  top: 0;
}
.end-0 {
  right: 0;
}
</style>
 