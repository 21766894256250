<template>
    <div class="container mt-5">
      <h1 class="mb-4">Gerador de números para Lotofácil</h1>
  
      <!-- Input para quantidade de números (15-20) -->
      <div class="form-group mb-3">
        <label for="numCount">Quantidade de números (15-20):</label>
        <input type="range" class="form-range" min="15" max="20" v-model="numCount">
        <input type="number" class="form-control mt-2" v-model="numCount" min="15" max="20">
      </div>
  
      <!-- Input para quantidade de jogos -->
      <div class="form-group mb-3">
        <label for="listCount">Quantidade de jogos:</label>
        <input type="number" class="form-control" v-model="listCount" min="1" placeholder="Quantos jogos deseja gerar?">
      </div>
  
      <!-- Input para separador de números -->
      <div class="form-group mb-3">
        <label for="separator">Separador de números:</label>
        <input type="text" class="form-control" v-model="separator" placeholder="Digite o separador desejado (ex: vírgula, ponto e vírgula)">
      </div>
  
      <!-- Botão para gerar os números -->
      <button class="btn btn-primary mb-3" @click="generateNumbers">Gerar Números</button>
  
      <!-- Área para exibir os números gerados -->
      <div v-if="generatedNumbers.length">
        <h4>Listas de Números Gerados:</h4>
        <textarea class="form-control" rows="6" readonly v-model="generatedNumbersText"></textarea>
  
        <!-- Botão para copiar os números gerados -->
        <button class="btn btn-secondary mt-3" @click="copyToClipboard">Copiar Números Gerados</button>
      </div>
    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2';
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';
  export default {
    data() {
      return {
        numCount: 15,   // Quantidade de números por lista
        listCount: 1,   // Quantidade de listas
        separator: ', ', // Separador dos números
        generatedNumbers: [] // Armazena as listas geradas
      };
    },
    mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('lotofacil',encryptTimestamp);
    },
    computed: {
      generatedNumbersText() {
        return this.generatedNumbers.map(list => list.join(this.separator)).join('\n\n');
      }
    },
    methods: {
      // Função para gerar números randômicos únicos
      generateNumbers() {
        this.generatedNumbers = [];
  
        const generateRandomList = () => {
          const numbers = [];
          while (numbers.length < this.numCount) {
            const randomNum = Math.floor(Math.random() * 25) + 1;
            if (!numbers.includes(randomNum)) {
              numbers.push(randomNum);
            }
          }
          return numbers.sort((a, b) => a - b);  // Ordena os números
        };
  
        // Gera a quantidade de listas desejadas
        for (let i = 0; i < this.listCount; i++) {
          this.generatedNumbers.push(generateRandomList());
        }
      },
      // Função para copiar os números gerados
      copyToClipboard() {
      navigator.clipboard.writeText(this.generatedNumbersText).then(() => {
        Swal.fire({
            icon: 'success',
            title: 'Copiado!',
            text: 'Texto copiado para a área de transferência.',
            timer: 1000,
            showConfirmButton: false
          });
      });
    },
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  .position-relative {
    position: relative;
  }
  .position-absolute {
    position: absolute;
  }
  .top-0 {
    top: 0;
  }
  .end-0 {
    right: 0;
  }
  </style>
   