<template>
  <aside :class="['bg-light', 'border-end', 'min-vh-100', { 'sidebar-collapsed': isCollapsed }]">
    <div class="d-flex justify-content-between align-items-center p-2">
      <h5 class="mb-0">
        <span v-if="!isCollapsed">Webtools</span>
      </h5>
      <button class="btn btn-sm btn-outline-primary" @click="toggleSidebar">
        <font-awesome-icon :icon="isCollapsed ? 'angle-right' : 'angle-left'" />
      </button>
    </div>
    <div v-if="!isCollapsed" class="list-group list-group-flush">


      <!-- Menu Principal com Submenu Sempre Aberto

      <a href="#dashboard-submenu" data-bs-toggle="collapse" aria-expanded="true" class="list-group-item list-group-item-action">
        <font-awesome-icon icon="computer" class="me-2" />
        <span v-if="!isCollapsed">COMPUTAÇÃO</span>
        <font-awesome-icon icon="angle-down" class="ms-2" v-if="!isCollapsed" />
      </a>

      <div class="collapse show" id="dashboard-submenu">
        <router-link to="/computacao/meu-ip" class="list-group-item list-group-item-action ps-5">
          <font-awesome-icon icon="network-wired" class="me-2" />
          <span v-if="!isCollapsed">Meu IP</span>
        </router-link>
        <router-link to="/computacao/meu-navegador" class="list-group-item list-group-item-action ps-5">
          <font-awesome-icon icon="window-maximize" class="me-2" />
          <span v-if="!isCollapsed">Meu Navegador</span>
        </router-link>
        <router-link to="/computacao/minha-tela" class="list-group-item list-group-item-action ps-5">
          <font-awesome-icon icon="desktop" class="me-2" />
          <span v-if="!isCollapsed">Minha Tela</span>
        </router-link>
        <router-link to="/computacao/minha-localizacao" class="list-group-item list-group-item-action ps-5">
          <font-awesome-icon icon="map-location" class="me-2" />
          <span v-if="!isCollapsed">Minha Localização</span>
        </router-link>
      </div>

      <a href="#submenu1" data-bs-toggle="collapse" class="list-group-item list-group-item-action">
        <font-awesome-icon icon="font" class="me-2" />
        <span v-if="!isCollapsed">FUNÇÕES STRING</span>
        <font-awesome-icon icon="angle-down" class="ms-2" v-if="!isCollapsed" />
      </a>
      <div class="collapse show" id="submenu1">
        <router-link to="/string/inverter-texto" class="list-group-item list-group-item-action ps-5">
          <font-awesome-icon icon="rotate-left" class="me-2" />
          <span v-if="!isCollapsed">Inverter Texto</span>
        </router-link>
        <router-link to="/string/dividir-texto" class="list-group-item list-group-item-action ps-5">
          <font-awesome-icon icon="arrows-split-up-and-left" class="me-2" />
          <span v-if="!isCollapsed">Dividir Texto</span>
        </router-link>
        <router-link to="/string/contador-texto" class="list-group-item list-group-item-action ps-5">
          <font-awesome-icon icon="list-ol" class="me-2" />
          <span v-if="!isCollapsed">Contador Texto</span>
        </router-link>
      </div>
      
      
      <a href="#submenu2" data-bs-toggle="collapse" class="list-group-item list-group-item-action">
        <font-awesome-icon icon="calendar" class="me-1" />
        <span v-if="!isCollapsed">Data e Hora</span>
        <font-awesome-icon icon="angle-down" class="ms-2" v-if="!isCollapsed" />
      </a>

      <div class="collapse show" id="submenu2">
        <router-link to="/data-hora/cronometro" class="list-group-item list-group-item-action ps-5">
          <font-awesome-icon icon="stopwatch" class="me-2" />
          <span v-if="!isCollapsed">Cronômetro</span>
        </router-link>
      </div>
    
      -->

      <div v-for="(categoria, index) in categoriasList">
        <a :key="categoria.pagina" :href="'#submenu-'+index" data-bs-toggle="collapse" class="list-group-item list-group-item-action">
          <font-awesome-icon :icon=categoria.icone class="me-1" />
          <span v-if="!isCollapsed">{{ categoria.nome.toUpperCase() }}</span>
          <font-awesome-icon icon="angle-down" class="ms-2" v-if="!isCollapsed" />
        </a>

        <div class="collapse show" :id="'submenu-'+index">
          <span v-for="ferramentas in categoria.ferramentas">
              <router-link :to=ferramentas.link class="list-group-item list-group-item-action ps-5">
                <font-awesome-icon :icon=ferramentas.icone class="me-2" />
                <span v-if="!isCollapsed">{{ ferramentas.nome }}</span>
              </router-link>
          </span>
        </div>
      </div>
       
    </div>
  </aside>
</template>

<script>
import ferramentas from '@/assets/data/ferramentas.js'
export default {
  name: 'Sidebar',
  data() {
    return {
      isCollapsed: false,
      categoriasList: [],
      ferramentasList: [],
    };
  },
  created() {
      this.categoriasList = ferramentas.categoria.sort((a, b) => a.ordem - b.ordem);
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isCollapsed = true;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    toggleSidebar() {
      this.isCollapsed = !this.isCollapsed;

    },
    handleResize() {
      if (window.innerWidth <= 768) {
        this.isCollapsed = true;
      } else {
        this.isCollapsed = false;
      }
    }
  }
};
</script>

<style scoped>
aside {
  width: 250px;
  transition: width 0.3s ease;
}

.sidebar-collapsed {
  width: 45px;
}

.list-group-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-collapsed .list-group-item {
  padding-left: 1rem;
}

.sidebar-collapsed .list-group-item .ps-5 {
  padding-left: 2rem !important;
}

.sidebar-collapsed .fa-icon {
  margin-right: 0;
}
</style>
