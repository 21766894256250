<template>
    <div class="container">
      <h1 class="mb-4">Inverter Texto</h1>
      <div class="mb-3">
        <label for="texto" class="form-label">Digite o texto:</label>
        <textarea v-model="texto" id="texto" rows="5" class="form-control"></textarea>
      </div>
      <button @click="inverterTexto" class="btn btn-primary">Inverter Texto</button>
    </div>
  </template>
  
  <script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';

  export default {
    data() {
      return {
        texto: ''
      };
    },
    mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('inverter-texto',encryptTimestamp);
    },
    methods: {
      inverterTexto() {
        this.texto = this.texto.split('').reverse().join('');
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  </style>
  