<template>
    <ToolsByCategory category="string"></ToolsByCategory>
  </template>
  
  <script>
  import ToolsByCategory from '@/components/ToolsByCategory.vue';
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';
  export default {
    name: "String",
    components:{
      ToolsByCategory
    },
    mounted() {
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('string',encryptTimestamp);
    },
  }
  </script>
  