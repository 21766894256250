<template>
    <div class="container">
      <h1 class="mb-4">Cortar Texto</h1>
      
      <div class="mb-3">
        <label for="texto">Digite o texto:</label>
        <textarea id="texto" v-model="texto" class="form-control" rows="5"></textarea>
      </div>

      <!-- Campo para informar a posição numérica -->
      <div class="mb-3">
        <label for="cutPosition">Posição para Cortar:</label>
        <input type="number" id="cutPosition" v-model.number="cutPosition" class="form-control" min="1" />
      </div>

      <div class="form-group mb-3">
        <label>Adicionar Reticências:</label>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="ellipsisYes" value="true" v-model="addEllipsis">
        <label class="form-check-label" for="ellipsisYes">Sim</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="ellipsisNo" value="false" v-model="addEllipsis">
        <label class="form-check-label" for="ellipsisNo">Não</label>
      </div>
    </div>

      <!-- Botão Cortar Texto -->
      <button class="btn btn-primary mb-3" @click="cutText">Cortar Texto</button>

      <!-- Text Area para mostrar o resultado -->
      <div class="form-group mb-3">
        <label for="resultText">Resultado:</label>
        <textarea id="resultText" v-model="resultText" class="form-control" rows="5" readonly></textarea>
      </div>


    </div>
  </template>
  
  <script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';

  export default {
    data() {
      return {
        texto: '',      // Texto inserido pelo usuário
        cutPosition: null,  // Posição onde o texto será cortado
        addEllipsis: 'no',  // Opção para adicionar reticências
        resultText: '',     // Resultado do texto cortado
      };
    },

    mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('cortar-texto',encryptTimestamp);
    },
    methods: {
      cutText() {
        // Verifica se a posição de corte é válida
        if (this.cutPosition > 0 && this.cutPosition <= this.texto.length) {
          // Corta o texto na posição informada
          this.resultText = this.texto.slice(0, this.cutPosition);
          
          // Adiciona reticências se a opção for escolhida
          if (this.addEllipsis === 'yes') {
            this.resultText += '...';
          }
        } else {
          this.resultText = 'Posição de corte inválida!';
        }
      },
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  </style>
  