<template>
     <div class="container">
      <h1 class="mb-4">Termos de uso</h1>
    </div>
  </template>
  
  <script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';

  export default {
    name: 'TermosDeUso',
    mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('termos-de-uso',encryptTimestamp);
    }
  };
  </script>
  