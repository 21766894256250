<template>
    <div class="container">
      <h1 class="mb-4">Gerador de Texto Aleatório</h1>
        
      <div class="form-group mb-3">
        <label for="typeSelect">Tipo de Geração</label>
        <select class="form-control" id="typeSelect" v-model="type">
          <option value="paragraphs">Parágrafos</option>
          <option value="words">Palavras</option>
          <option value="sentences">Sentenças</option>
        </select>
      </div>
  
      <div class="form-group mb-3">
        <label for="amountInput">Quantidade</label>
        <input type="number" class="form-control" id="amountInput" v-model="amount" min="1" />
      </div>
  
      <div class="form-group mb-3">
        <button class="btn btn-primary" @click="generateText">Gerar Texto</button>
    

      </div>

      <div v-if="generatedText">
        <div class="d-flex bd-highlight mb-3">
            <h4 class="bd-highlight">Texto Gerado:</h4>

            <button class="btn btn-secondary ms-auto bd-highlight" @click="copyToClipboard">
                Copiar <font-awesome-icon icon="copy" class="" />
            </button>
        </div>

        <div class="position-relative">
          <textarea v-model="generatedText" id="texto" rows="7" class="form-control"></textarea>
        </div>

      </div>

    </div>
  </template>
  
  <script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';
  import { LoremIpsum } from 'lorem-ipsum';
  import Swal from 'sweetalert2';
  
  export default {
    data() {
      return {
        type: 'paragraphs',
        amount: 1,
        generatedText: ''
      };
    },
    mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('gerador-texto',encryptTimestamp);
    },
    methods: {
      generateText() {
        const lorem = new LoremIpsum({
          sentencesPerParagraph: {
            max: 8,
            min: 4
          },
          wordsPerSentence: {
            max: 16,
            min: 4
          },
          format: 'plain'
        });
  
        if (this.type === 'paragraphs') {
          const paragraphs = lorem.generateParagraphs(this.amount);
          this.generatedText = paragraphs.split('\n').join('\n\n');
        } else if (this.type === 'words') {
          this.generatedText = lorem.generateWords(this.amount);
        } else if (this.type === 'sentences') {
          this.generatedText = lorem.generateSentences(this.amount);
        }
        this.adjustTextareaHeight();
      },
      copyToClipboard() {
        if(document.getElementById('texto')){
            const textarea = document.getElementById('texto');
          textarea.select();
          document.execCommand('copy');
          Swal.fire({
            icon: 'success',
            title: 'Copiado!',
            text: 'Texto copiado para a área de transferência.',
            timer: 1000,
            showConfirmButton: false
          });
        }
      },
      adjustTextareaHeight() {
        this.$nextTick(() => {
          const textarea = document.getElementById('texto');
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight+10}px`;
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  .position-relative {
    position: relative;
  }
  .position-absolute {
    position: absolute;
  }
  .top-0 {
    top: 0;
  }
  .end-0 {
    right: 0;
  }
  </style>
  