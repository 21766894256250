<template>
  <div class="container">
    <h1 class="mb-4">Gerador de Senhas</h1>
    
    <!-- Quantidade de caracteres -->
    <div class="form-group mb-3">
      <label for="charLength">Quantidade de caracteres:</label>
      <input type="range" class="form-range" id="charLength" v-model="passwordLength" min="4" max="50">
      <input type="number" class="form-control mt-2" v-model="passwordLength" min="4" max="50">
    </div>

    <!-- Quantidade de senhas -->
    <div class="form-group mb-3">
      <label for="passwordCount">Quantidade de senhas a gerar:</label>
      <input type="number" class="form-control" id="passwordCount" v-model="passwordCount" min="1">
    </div>

    <!-- Opções de caracteres -->
    <div class="form-check mb-2">
      <input class="form-check-input" type="checkbox" id="uppercase" v-model="includeUppercase">
      <label class="form-check-label" for="uppercase">
        Incluir Letras Maiúsculas
      </label>
    </div>

    <div class="form-check mb-2">
      <input class="form-check-input" type="checkbox" id="lowercase" v-model="includeLowercase">
      <label class="form-check-label" for="lowercase">
        Incluir Letras Minúsculas
      </label>
    </div>

    <div class="form-check mb-2">
      <input class="form-check-input" type="checkbox" id="numbers" v-model="includeNumbers">
      <label class="form-check-label" for="numbers">
        Incluir Números
      </label>
    </div>

    <div class="form-check mb-2">
      <input class="form-check-input" type="checkbox" id="symbols" v-model="includeSymbols">
      <label class="form-check-label" for="symbols">
        Incluir Símbolos
      </label>
    </div>

    <!-- Botão para gerar senhas -->
    <button class="btn btn-primary mb-3" @click="generatePasswords">Gerar Senhas</button>

    <!-- Textarea com as senhas -->
    <div v-if="generatedPasswords.length > 0">
      <h4>Senhas Geradas:</h4>
      <textarea v-model="generatedPasswords" class="form-control" rows="6" readonly></textarea>

      <!-- Botão para copiar senhas -->
      <button class="btn btn-secondary mt-3" @click="copyToClipboard">Copiar Senhas</button>
    </div>

  </div>
</template>

<script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';
  import Swal from 'sweetalert2';

export default {
  data() {
    return {
      passwordLength: 8,
      passwordCount: 1,
      includeUppercase: true,
      includeLowercase: true,
      includeNumbers: true,
      includeSymbols: true,
      generatedPasswords: ''
    };
  },
  mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('gerador-senha',encryptTimestamp);
    },
  methods: {
    generatePasswords() {
      const upperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const lowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
      const numbers = "0123456789";
      const symbols = "!@#$%^&*()_+~`|}{[]:;?><,./-=";

      let characterPool = "";

      if (this.includeUppercase) characterPool += upperCaseLetters;
      if (this.includeLowercase) characterPool += lowerCaseLetters;
      if (this.includeNumbers) characterPool += numbers;
      if (this.includeSymbols) characterPool += symbols;

      if (!characterPool) {
        alert("Selecione ao menos uma opção de caractere!");
        return;
      }

      let passwords = "";

      for (let i = 0; i < this.passwordCount; i++) {
        let password = "";
        for (let j = 0; j < this.passwordLength; j++) {
          const randomIndex = Math.floor(Math.random() * characterPool.length);
          password += characterPool[randomIndex];
        }
        passwords += password + "\n";
      }

      this.generatedPasswords = passwords.trim();
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.generatedPasswords).then(() => {
        Swal.fire({
            icon: 'success',
            title: 'Copiado!',
            text: 'Texto copiado para a área de transferência.',
            timer: 1000,
            showConfirmButton: false
          });
      });
    },
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.top-0 {
  top: 0;
}
.end-0 {
  right: 0;
}
</style>