import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

import router from './router';  // Importa o router

router.afterEach((to) => {
    gtag('config', 'G-H7Q63YZ6D4', { 'page_path': to.fullPath });
  });


// eslint-disable-next-line  
delete L.Icon.Default.prototype._getIconUrl  
// eslint-disable-next-line  
L.Icon.Default.mergeOptions({  
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
  iconUrl: require('leaflet/dist/images/marker-icon.png'),  
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')  
})

// Adiciona todos os ícones do pacote solid à biblioteca
library.add(fas)

// Cria a aplicação Vue
const app = createApp(App)

// Usa o router na aplicação
app.use(router);  

// Registra o componente FontAwesomeIcon globalmente
app.component('font-awesome-icon', FontAwesomeIcon)



// Monta a aplicação
app.mount('#app')
