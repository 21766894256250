<template>
    <div class="container">
      <h1 class="mb-4">Teste de Webcam</h1>
  
      <!-- Seleção da câmera (se houver mais de uma) -->
      <div class="form-group mb-3" v-if="videoDevices.length > 1">
        <label for="cameraSelect">Selecione a Webcam:</label>
        <select id="cameraSelect" class="form-control" v-model="selectedDeviceId" @change="startWebcam">
          <option v-for="device in videoDevices" :key="device.deviceId" :value="device.deviceId">
            {{ device.label || `Camera ${device.deviceId}` }}
          </option>
        </select>
      </div>
  
      <!-- Botão para iniciar ou parar a webcam -->
      <div class="mb-3">
        <button class="btn btn-primary" v-if="!webcamActive" @click="startWebcam">Iniciar Webcam</button>
        <button class="btn btn-danger" v-if="webcamActive" @click="stopWebcam">Parar Webcam</button>
      </div>
  
      <!-- Elemento de vídeo para exibir a webcam -->
      <div v-if="webcamActive">
        <video ref="webcam" width="100%" autoplay></video>
      </div>
  
      <!-- Botão para tirar uma foto -->
      <div v-if="webcamActive" class="mt-3">
        <button class="btn btn-success" @click="takePhoto">Tirar Foto</button>
      </div>
  
      <!-- Exibir a foto tirada -->
      <div v-if="photo" class="mt-3">
        <h4>Foto Capturada:</h4>
        <img :src="photo" alt="Foto capturada" class="img-fluid"/>
      </div>
  
      <!-- Mensagem de erro, se houver -->
      <div v-if="errorMessage" class="alert alert-danger mt-3">
        {{ errorMessage }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        webcamActive: false,    // Indica se a webcam está ativa
        errorMessage: '',       // Armazena a mensagem de erro, se houver
        videoStream: null,      // Armazena o stream de vídeo
        photo: null,            // Armazena a foto capturada
        videoDevices: [],       // Lista de dispositivos de vídeo disponíveis
        selectedDeviceId: ''    // ID da webcam selecionada
      };
    },
    mounted() {
      // Carrega a lista de webcams disponíveis quando o componente for montado
      this.getVideoDevices();
    },
    methods: {
      // Função para iniciar a webcam
      startWebcam() {
        const constraints = {
          video: {
            deviceId: this.selectedDeviceId ? { exact: this.selectedDeviceId } : undefined
          }
        };
  
        navigator.mediaDevices.getUserMedia(constraints)
          .then((stream) => {
            this.videoStream = stream;
            this.webcamActive = true;
  
            // Garantir que o DOM seja atualizado antes de acessar o elemento de vídeo
            this.$nextTick(() => {
              const video = this.$refs.webcam;
              if (video) {
                video.srcObject = stream;
              }
            });
          })
          .catch((error) => {
            this.errorMessage = 'Erro ao acessar a webcam: ' + error.message;
          });
      },
  
      // Função para parar a webcam
      stopWebcam() {
        if (this.videoStream) {
          this.videoStream.getTracks().forEach(track => track.stop());
        }
        this.webcamActive = false;
        this.videoStream = null;
      },
  
      // Função para tirar uma foto
      takePhoto() {
        const video = this.$refs.webcam;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.photo = canvas.toDataURL('image/png');
      },
  
      // Função para obter a lista de dispositivos de vídeo (webcams)
      getVideoDevices() {
        navigator.mediaDevices.enumerateDevices()
          .then(devices => {
            this.videoDevices = devices.filter(device => device.kind === 'videoinput');
            if (this.videoDevices.length > 0) {
              this.selectedDeviceId = this.videoDevices[0].deviceId;
            }
          })
          .catch(error => {
            this.errorMessage = 'Erro ao listar dispositivos de vídeo: ' + error.message;
          });
      }
    }
  };
  </script>
  
 
<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.top-0 {
  top: 0;
}
.end-0 {
  right: 0;
}
video {
    border: 2px solid #007bff;
    border-radius: 8px;
  }
img {
    border: 2px solid #28a745;
    border-radius: 8px;
}
</style>
 