<template>
    <div class="container">
      <h1 class="mb-4">Teste e Gravação de Áudio</h1>
  
      <div class="mb-3">
        <label for="mic-select" class="form-label">Escolha um Microfone:</label>
        <select id="mic-select" v-model="selectedMic" class="form-select mb-3">
          <option v-for="mic in microphones" :key="mic.deviceId" :value="mic.deviceId">
            {{ mic.label || 'Microfone Sem Nome' }}
          </option>
        </select>
  
        <button class="btn btn-primary me-2" @click="startRecording" :disabled="isRecording">
          {{ isRecording ? 'Gravando...' : 'Iniciar Gravação' }}
        </button>
  
        <button class="btn btn-secondary me-2" @click="stopRecording" :disabled="!isRecording">
          Parar Gravação
        </button>
  
        <button class="btn btn-danger" @click="resetRecording" :disabled="!audioBlob">
          Resetar Gravação
        </button>
      </div>
  
      <div v-if="isRecording" class="mt-3">
        <h4 class="d-none">Medidor de Decibéis:</h4>
        <div class="d-flex justify-content-center">
          <div class="decibel-meter">
            <div class="level" :style="{ height: decibelHeight + '%' }"></div>
          </div>
          <div class="decibel-value d-none">{{ decibelLevel.toFixed(2) }} dB</div>
        </div>
      </div>
  
      <div v-if="audioUrl" class="mt-3">
        <h4>Áudio Gravado:</h4>
        <audio :src="audioUrl" controls></audio>
      </div>
  
      <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isRecording: false,
        mediaRecorder: null,
        audioChunks: [],
        audioBlob: null,
        audioUrl: null,
        errorMessage: null,
        audioContext: null,
        analyser: null,
        decibelLevel: 0,
        decibelHeight: 0,
        animationFrameId: null,
        micStream: null, // Nova propriedade para o fluxo do microfone
        microphones: [], // Lista de microfones disponíveis
        selectedMic: null // Microfone selecionado
      };
    },
    mounted() {
      this.getMicrophones(); // Chamar a função para obter os microfones disponíveis ao montar o componente
    },
    methods: {
      async getMicrophones() {
        try {
          const devices = await navigator.mediaDevices.enumerateDevices();
          this.microphones = devices.filter(device => device.kind === 'audioinput');
          
          // Se não houver microfones, selecionar o primeiro disponível
          if (this.microphones.length > 0) {
            this.selectedMic = this.microphones[0].deviceId; // Seleciona o primeiro microfone
          }
        } catch (error) {
          this.errorMessage = 'Erro ao obter microfones: ' + error.message;
        }
      },
      async startRecording() {
        this.errorMessage = null;
        try {
          // Usar o microfone selecionado
          this.micStream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: this.selectedMic } });
          this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
          const source = this.audioContext.createMediaStreamSource(this.micStream);
          this.analyser = this.audioContext.createAnalyser();
          source.connect(this.analyser);
  
          this.analyser.fftSize = 2048; // Tamanho do FFT
          this.analyser.smoothingTimeConstant = 0.3; // Suavização
  
          // Iniciar medição de decibéis
          this.measureDecibels();
  
          this.mediaRecorder = new MediaRecorder(this.micStream);
          this.mediaRecorder.ondataavailable = (event) => {
            this.audioChunks.push(event.data);
          };
  
          this.mediaRecorder.onstop = () => {
            this.audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
            this.audioUrl = URL.createObjectURL(this.audioBlob);
            this.audioChunks = [];
            cancelAnimationFrame(this.animationFrameId); // Parar medição
            this.audioContext.close(); // Fechar o contexto do áudio
          };
  
          this.mediaRecorder.start();
          this.isRecording = true;
        } catch (error) {
          this.errorMessage = 'Erro ao acessar o microfone: ' + error.message;
        }
      },
      stopRecording() {
        if (this.mediaRecorder && this.isRecording) {
          this.mediaRecorder.stop();
          this.isRecording = false;
          this.micStream.getTracks().forEach(track => track.stop()); // Parar os rastros de áudio
        }
      },
      resetRecording() {
        this.audioBlob = null;
        this.audioUrl = null;
        this.decibelLevel = 0;
        this.decibelHeight = 0;
      },
      measureDecibels() {
        this.animationFrameId = requestAnimationFrame(this.measureDecibels);
        const dataArray = new Uint8Array(this.analyser.frequencyBinCount);
        this.analyser.getByteFrequencyData(dataArray); // Mudança para obter dados de frequência
  
        // Calcular o nível de decibéis
        const sum = dataArray.reduce((a, b) => a + b, 0);
        const average = sum / dataArray.length;
  
        // Calcular o nível de decibéis em relação ao valor médio
        this.decibelLevel = 20 * Math.log10(average / 128); // Ajuste para o valor médio
        this.decibelHeight = Math.max(0, Math.min(100, (this.decibelLevel + 80))); // Para um ajuste visual
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  audio {
    width: 100%;
    margin-top: 20px;
  }
  
  .decibel-meter {
    width: 100px;
    height: 150px;
    border: 2px solid #4caf50;
    border-radius: 8px;
    position: relative;
    margin: 0 auto;
  }
  
  .level {
    background-color: #4caf50;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  .decibel-value {
    margin-top: 10px;
    font-size: 1.5em;
  }
  </style>
  