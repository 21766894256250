<template>
  <div>
    <Header />
    <div class="d-flex">
      <Sidebar />
      <main class="container-fluid mt-3">
        <router-view />
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>

import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import Sidebar from './components/Sidebar.vue';


export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Sidebar,
    
  }
}
</script>



<style>
  @import url(assets/style/global.css);
</style>