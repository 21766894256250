const ferramentas = 
{
    "categoria": [
        {
            "nome" : "String",
            "slug": "string",
            "link": "/string",
            "ordem": 2,
            "icone": "font",
            "ferramentas": [
                {
                    "nome":"Inverter Texto",
                    "descricao" : "",
                    "link": "/string/inverter-texto",
                    "pagina": "inverter-texto",
                    "icone": "rotate-left",
                    "ordem": 1
                },
                {
                    "nome":"Dividir Texto",
                    "descricao" : "",
                    "link": "/string/dividir-texto",
                    "pagina": "dividir-texto",
                    "icone": "arrows-split-up-and-left",
                    "ordem": 2
                },
                {
                    "nome":"Contador Texto",
                    "descricao" : "",
                    "link": "/string/contador-texto",
                    "pagina": "contador-texto",
                    "icone": "list-ol",
                    "ordem": 3
                },
                {
                    "nome":"Cortar Texto",
                    "descricao" : "",
                    "link": "/string/cortar-texto",
                    "pagina": "cortar-texto",
                    "icone": "scissors",
                    "ordem": 4
                },
                {
                    "nome":"Contar Palavra",
                    "descricao" : "",
                    "link": "/string/contador-palavra",
                    "pagina": "contador-palavra",
                    "icone": "spell-check",
                    "ordem": 5
                },
                {
                    "nome":"Gerador Texto",
                    "descricao" : "",
                    "link": "/string/gerador-texto",
                    "pagina": "gerador-texto",
                    "icone": "envelope-open-text",
                    "ordem": 6
                },
                {
                    "nome":"Gerador Senha",
                    "descricao" : "",
                    "link": "/string/gerador-senha",
                    "pagina": "gerador-senha",
                    "icone": "lock",
                    "ordem": 7
                },
                {
                    "nome":"Conversor Maiúsculas e Minúsculas",
                    "descricao" : "",
                    "link": "/string/conversor-maiusculas-minusculas",
                    "pagina": "conversor-maiusculas-minusculas",
                    "icone": "text-height",
                    "ordem": 8
                },
                
            ]
        },
        {
            "nome" : "Computação",
            "slug": "computacao",
            "link": "/computacao",
            "ordem": 1,
            "icone": "computer",
            "ferramentas": [
                {
                    "nome":"Meu IP",
                    "descricao" : "",
                    "link": "/computacao/meu-ip",
                    "pagina": "meu-ip",
                    "icone": "network-wired",
                    "ordem": 1
                },
                {
                    "nome":"Meu Navegador",
                    "descricao" : "",
                    "link": "/computacao/meu-navegador",
                    "pagina": "meu-navegador",
                    "icone": "window-maximize",
                    "ordem": 2
                },
                {
                    "nome":"Minha Tela",
                    "descricao" : "",
                    "link": "/computacao/minha-tela",
                    "pagina": "minha-tela",
                    "icone": "desktop",
                    "ordem": 3
                },
                {
                    "nome":"Minha Localização",
                    "descricao" : "",
                    "link": "/computacao/minha-localizacao",
                    "pagina": "minha-localizacao",
                    "icone": "map-location",
                    "ordem": 4
                },
                {
                    "nome":"Webcam",
                    "descricao" : "",
                    "link": "/computacao/webcam",
                    "pagina": "webcam",
                    "icone": "camera",
                    "ordem": 5
                },
                {
                    "nome":"Microfone",
                    "descricao" : "",
                    "link": "/computacao/microfone",
                    "pagina": "microfone",
                    "icone": "microphone",
                    "ordem": 6
                },
                {
                    "nome":"Mouse",
                    "descricao" : "",
                    "link": "/computacao/mouse",
                    "pagina": "mouse",
                    "icone": "computer-mouse",
                    "ordem": 7
                },
                {
                    "nome":"Teclado",
                    "descricao" : "",
                    "link": "/computacao/teclado",
                    "pagina": "teclado",
                    "icone": "keyboard",
                    "ordem": 8
                },
            ]
        },
        {
            "nome" : "Criptografia",
            "slug": "criptografia",
            "link": "/criptografia",
            "ordem": 3,
            "icone": "user-secret",
            "ferramentas": [
                {
                    "nome":"Hash MD5",
                    "descricao" : "",
                    "link": "/criptografia/gerador-hash-md5",
                    "pagina": "gerador-hash-md5",
                    "icone": "key",
                    "ordem": 1
                },
                {
                    "nome":"Hash SHA-1",
                    "descricao" : "",
                    "link": "/criptografia/gerador-hash-sha1",
                    "pagina": "gerador-hash-sha1",
                    "icone": "key",
                    "ordem": 2
                },
                {
                    "nome":"Hash SHA-256",
                    "descricao" : "",
                    "link": "/criptografia/gerador-hash-sha256",
                    "pagina": "gerador-hash-sha256",
                    "icone": "key",
                    "ordem": 3
                },
                {
                    "nome":"Hash SHA-224",
                    "descricao" : "",
                    "link": "/criptografia/gerador-hash-sha224",
                    "pagina": "gerador-hash-sha224",
                    "icone": "key",
                    "ordem": 4
                },
                {
                    "nome":"Hash SHA-512",
                    "descricao" : "",
                    "link": "/criptografia/gerador-hash-sha512",
                    "pagina": "gerador-hash-sha512",
                    "icone": "key",
                    "ordem": 5
                }
            ]
        },
        {
            "nome" : "Loteria",
            "slug": "loteria",
            "link": "/loteria",
            "ordem": 4,
            "icone": "shuffle",
            "ferramentas": [
                {
                    "nome":"Lotofácil",
                    "descricao" : "",
                    "link": "/loteria/lotofacil",
                    "pagina": "Lotofacil",
                    "icone": "key",
                    "ordem": 1
                }
            ]
        },
        {
            "nome" : "Data e Hora",
            "slug": "data-hora",
            "link": "/data-hora",
            "ordem": 5,
            "icone": "calendar",
            "ferramentas": [
                {
                    "nome":"Cronômeto",
                    "descricao" : "",
                    "link": "/data-hora/cronometro",
                    "pagina": "cronometro",
                    "icone": "stopwatch",
                    "ordem": 1
                },
                {
                    "nome":"Dia e Horário",
                    "descricao" : "",
                    "link": "/data-hora/dia-horario",
                    "pagina": "dia-horario",
                    "icone": "calendar-day",
                    "ordem": 2
                }
            ]
        }

    ]
}

export default ferramentas;