<template>
    <div class="container">
      <h1 class="mb-4">Seu Endereço IP</h1>
      <div v-if="loading" class="spinner-border" role="status">
        <span class="visually-hidden">Carregando...</span>
      </div>
      <div v-if="error" class="alert alert-danger">
        {{ error }}
      </div>
      <div v-if="!loading && !error">
        <p><strong>IPv4:</strong> {{ ipv4 }}</p>
        <p><strong>IPv6:</strong> {{ ipv6 }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';
  export default {
    data() {
      return {
        ipv4: null,
        ipv6: null,
        loading: true,
        error: null,
      };
    },
    mounted() {
      this.fetchIPAddresses();
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('meu-ip',encryptTimestamp);
    },
    methods: {
      async fetchIPAddresses() {
        try {
          // Obter IPv4
          const ipv4Response = await axios.get('https://api.ipify.org?format=json');
          this.ipv4 = ipv4Response.data.ip;
  
          // Obter IPv6
          const ipv6Response = await axios.get('https://api64.ipify.org?format=json');
          this.ipv6 = ipv6Response.data.ip;
  
          this.loading = false;
        } catch (error) {
          this.error = 'Não foi possível obter o endereço IP.';
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  </style>
  