<template>
    <footer class="bg-dark text-white text-center py-3">
      <div class="container">
        <p>&copy; 2024 Webtools. Todos os direitos reservados.</p>
        <router-link to="/politica-de-privacidade" class="text-white">Política de Privacidade</router-link>
        <router-link to="/termos-de-uso" class="text-white">Termos de Uso</router-link>
      </div>
    </footer>
  </template>
  
  <script>

  export default {
    name: 'Footer'
  };
  </script>
  
  <style scoped>
  footer a {
    margin: 0 0.5rem;
    text-decoration: none;
  }
  </style>
  