<template>
    <div class="container">
      <h1 class="mt-4">Teste de Funcionamento do Teclado</h1>
      <p>Pressione qualquer tecla para ver o feedback.</p>
  
      <!-- Mostrando a tecla pressionada -->
      <div class="mt-3">
        <h3>Tecla Pressionada: {{ getDisplayName(pressedKey) }}</h3>
      </div>

      <!-- Exibição das teclas -->
      <div class="keyboard mt-4">
        <!-- Teclas especiais -->
        <div class="keys-row">
          <span
            v-for="key in specialKeys"
            :key="key"
            :class="{ 'key-big': true, 'key': true, 'active': pressedKey === key }"
          >{{ getDisplayName(key) }}</span>
        </div>
  
        <!-- Teclas regulares -->
        <div class="keys-row">
          <span
            v-for="key in keys"
            :key="key"
            :class="{ 'key': true, 'active': pressedKey === key }"
          >{{ key }}</span>
        </div>
      </div>
  

    </div>
  </template>
  
  <script>
export default {
  data() {
    return {
      pressedKey: null,
      pressedKeyName: null,
      specialKeys: [
        'Shift', 'Control', 'Alt', 'AltGraph', 'Meta', 'CapsLock', 'Escape', 
        'Tab', 'Enter', 'Space', 'Backspace', 'Delete', 'ArrowUp', 
        'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Home', 'End', 
        'PageUp', 'PageDown', 'Insert', 'F1', 'F2', 'F3', 'F4', 
        'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12', " "
      ],
      keys: [
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 
        'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 
        'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', 
        '4', '5', '6', '7', '8', '9', '`', '-', '=', '[', 
        ']', '\\', ';', '\'', ',', '.', '/', '+'
      ]
    };
  },
  methods: {
    handleKeyPress(event) {
      this.pressedKey = event.key;
    },
    getDisplayName(key) {
      // Mapeamento de teclas com nomes personalizados
      const keyMap = {
        'ArrowRight': 'Seta Direita',
        'ArrowLeft': 'Seta Esquerda',
        'ArrowUp': 'Seta Cima',
        'ArrowDown': 'Seta Baixo',
        'Enter': 'Enter (Retorno)',
        'Space': 'Espaço',
        " ": 'Espaço',
        // Adicione outras teclas aqui se necessário
      };
      return keyMap[key] || key; // Retorna o nome personalizado ou o nome original da tecla
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyPress);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  }
};
</script>
<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.keys-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.key {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 5px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.key-small {
    width: 40px;
    height: 40px;
}
.key-medium{
    width: 90px;
    height: 40px;
}
.key-big{
    width: 90px;
    height: 40px;
}

.key.active {
  background-color: #4caf50;
  color: white;
}
</style>
