<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <!-- Brand -->
        <a class="navbar-brand" href="#">Webtools</a>
  
        <!-- Toggler for mobile view -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">

            <li class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>

            <li v-for="categoria in categoriasList" class="nav-item">
              <router-link class="nav-link" :to=categoria.link>{{ categoria.nome }}</router-link>
            </li>
            <!--
            <li class="nav-item">
              <router-link class="nav-link" to="/computacao">Computação</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/string">Texto</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/data-hora">Data e Hora</router-link>
            </li>
            -->
            <!--
            <li class="nav-item dropdown">
              
              <a class="nav-link dropdown-toggle" href="#" id="dropdownMenuButton" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Sobre
              </a>
              
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><a class="dropdown-item" href="#">Nossa História</a></li>
                <li><a class="dropdown-item" href="#">Equipe</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Missão e Visão</a></li>
              </ul>
            </li>
            -->

          </ul>
        </div>
      </div>
    </nav>
  </template>
  
  <script>
  import ferramentas from '@/assets/data/ferramentas.js'
  export default {
    name: 'Header',
    data(){
      return{
        categoriasList: []
      }
    },
    created() {
      this.categoriasList = ferramentas.categoria.sort((a, b) => a.ordem - b.ordem);
  },
  }
  </script>
  
  <style scoped>
  /* Estilos personalizados para o cabeçalho, se necessário */
  </style>
  