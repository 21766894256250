<template>
    <div class="container">

        
      
        <div class="row text-center">
            <h1 class="form-label display-1 cronometro-font"> {{ currentTime }}</h1>
            <h1 class="form-label display-5"> {{ fullDate }}</h1>
            <h1 class="form-label display-5"> Dia {{ dayOfYear }}/{{ totalDaysInYear }}</h1>
        </div>
        <!-- Barra de Progresso -->
        
        <div class="d-flex justify-content-center d-none">
            <div class="progress mt-3 w-50 position-relative" style="height: 30px">
            <div class="progress-bar" role="progressbar" :style="{ width: progressPercentage + '%' }" :aria-valuenow="progressPercentage" aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-text position-absolute w-100 text-center text-white fs-5">
                    {{ progressPercentage }}%
                </div>
            </div>
        </div>

    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentTime: '',
        fullDate: '',
        timeZone: '',
        dayOfWeek: '',
        dayOfYear: 0,
        totalDaysInYear: 365,
        progressPercentage: 0
      };
    },
    mounted() {
      this.updateDateTime();
      setInterval(this.updateDateTime, 1000);
    },
    methods: {
      updateDateTime() {
        const now = new Date();
  
        // Data completa de hoje
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        this.fullDate = this.capitalizeFirstLetter(now.toLocaleDateString('pt-BR', options));
  
        // Relógio em tempo real
        this.currentTime = now.toLocaleTimeString('pt-BR');
  
        // TimeZone
        const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.timeZone = timeZoneName;
  
        // Dia da Semana
        this.dayOfWeek = now.toLocaleDateString('pt-BR', { weekday: 'long' });
  
        // Dias do Ano
        const start = new Date(now.getFullYear(), 0, 0);
        const diff = now - start + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
        const oneDay = 1000 * 60 * 60 * 24;
        this.dayOfYear = Math.floor(diff / oneDay);
  
        // Ajusta o total de dias no ano (365 ou 366)
        this.totalDaysInYear = this.isLeapYear(now.getFullYear()) ? 366 : 365;

        // Calcula a porcentagem de dias passados no ano
        this.progressPercentage = Math.round((this.dayOfYear / this.totalDaysInYear) * 100);

      },
      isLeapYear(year) {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      },
      capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
      }
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }

  @font-face {
    font-family: "clockicons";
    src: url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.eot");
    src: url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.eot?#iefix")format("embedded-opentype"),
    url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.woff2")format("woff2"),
    url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.woff")format("woff"),
    url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.ttf")format("truetype"),
    url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.svg#clockicons")format("svg");
  font-weight: normal;
  font-style: normal;
}

.cronometro-font {
    
    font-family: 'clockicons', sans-serif;
  }

  .progress-bar {
  position: relative;
  background-color: #007bff;
}

.progress-text {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  
}

  </style>
  