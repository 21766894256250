<template>
    
    <div class="container">
      <h1>Bem-vindo à Categoria {{categoriaNome}}</h1>
      <div class="row">
        <div class="col-md-2 mb-4" v-for="ferramenta in ferramentasList" :key="ferramenta.pagina">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{ ferramenta.nome }}</h5>
                    <p class="card-text">{{ ferramenta.descricao }}</p>
                    <router-link :to="ferramenta.link" class="btn btn-primary btn-sm">Acessar</router-link>
                </div>
            </div>
        </div>
      </div>
  </div>
  </template>
  
  <script>
  import ferramentas from '@/assets/data/ferramentas.js'

  export default {
    props: ['category'],
    name: "ToolByCategory",
    data(){
        return {
          ferramentasList: [],
          categoriaNome: '',
        };
    },
    created() {
    const categorias = ferramentas.categoria.find(categoria => categoria.slug === this.category);
    if (categorias) {
      this.ferramentasList = categorias.ferramentas;
      this.categoriaNome = categorias.nome;
    }
  },    
  }
  </script>
  