<template>
    <div class="container mt-5">
      <h1 class="mb-4">Gerador de Criptografia SHA-256</h1>
      
      <!-- Texto original -->
      <div class="form-group mb-3">
        <label for="originalText">Digite o texto:</label>
        <textarea id="originalText" class="form-control" rows="4" v-model="originalText" placeholder="Digite o texto aqui"></textarea>
      </div>
  
      <!-- Botão para gerar o texto criptografado -->
      <button class="btn btn-primary mb-3" @click="generateHash">Gerar Criptografia SHA-256</button>
  
      <!-- Texto criptografado -->
      <div v-if="hashedText">
        <h4>Texto Criptografado (SHA-256):</h4>
        <textarea class="form-control" rows="4" v-model="hashedText" readonly></textarea>
  
        <!-- Botão para copiar o texto gerado -->
        <button class="btn btn-secondary mt-3" @click="copyToClipboard">Copiar Texto Criptografado</button>
      </div>
    </div>
  </template>
  
  <script>
  import sha256 from 'crypto-js/sha256';
  import Swal from 'sweetalert2';
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';

  export default {
    data() {
      return {
        originalText: '',
        hashedText: ''
      };
    },
    mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('gerador-hash-sha256',encryptTimestamp);
    },
    methods: {
      generateHash() {
        this.hashedText = sha256(this.originalText).toString();
      },
      copyToClipboard() {
      navigator.clipboard.writeText(this.hashedText).then(() => {
        Swal.fire({
            icon: 'success',
            title: 'Copiado!',
            text: 'Texto copiado para a área de transferência.',
            timer: 1000,
            showConfirmButton: false
          });
      });
    },
    }
  };
  </script>

 <style scoped>
 .container {
   max-width: 800px;
   margin: 0 auto;
 }
 .position-relative {
   position: relative;
 }
 .position-absolute {
   position: absolute;
 }
 .top-0 {
   top: 0;
 }
 .end-0 {
   right: 0;
 }
 </style>
  