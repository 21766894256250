<template>
  <div class="container">
    <h1 class="mb-4">Cronômetro</h1>
    
    <div class="row">
        <h1 class="form-label display-1 cronometro-font"> {{ formattedTime }}</h1>
    </div>
    
      <div class="row">
        <div class="col mb-3"><button class="btn btn-lg btn-primary mb-3" @click="startTimer" :disabled="isRunning">Iniciar</button></div>
        <div class="col mb-3"><button class="btn btn-lg btn-primary mb-3" @click="pauseTimer" :disabled="!isRunning">Pausar</button></div>
        <div class="col mb-3"><button class="btn btn-lg btn-primary mb-3" @click="resetTimer">Reiniciar</button></div>
        <div class="col mb-3"><button class="btn btn-lg btn-primary mb-3" @click="registerLap" :disabled="!isRunning">Volta</button></div>
      </div>

      <div class="row">
        <ul class="col list-group list-group-flush">
        <li v-for="(lap, index) in laps.slice().reverse()" :key="index" class="list-group-item display-4">
          {{ laps.length - index }} - <span class="lap-time">{{ lap }}</span>
        </li>
      </ul>

      </div>

  </div>
 </template>
  
  <script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';
  export default {
    data() {
      return {
        centiseconds: 0,
        seconds: 0,
        minutes: 0,
        hours: 0,
        timer: null,
        isRunning: false,
        laps: [], // Lista de voltas registradas
      };
    },
    mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('cronometro',encryptTimestamp);
    },
    computed: {
      formattedTime() {
        const formatNumber = (num) => String(num).padStart(2, '0');
        return `${formatNumber(this.hours)}:${formatNumber(this.minutes)}:${formatNumber(this.seconds)}.${String(this.centiseconds).padStart(2, '0')}`;
      },
    },
    methods: {
      startTimer() {
        if (!this.isRunning) {
          this.isRunning = true;
          this.timer = setInterval(() => {
            this.centiseconds++;
            if (this.centiseconds === 100) {
              this.centiseconds = 0;
              this.seconds++;
            }
            if (this.seconds === 60) {
              this.seconds = 0;
              this.minutes++;
            }
            if (this.minutes === 60) {
              this.minutes = 0;
              this.hours++;
            }
          }, 10); // 10 ms for centiseconds
        }
      },
      pauseTimer() {
        this.isRunning = false;
        clearInterval(this.timer);
      },
      resetTimer() {
        this.pauseTimer();
        this.centiseconds = 0;
        this.seconds = 0;
        this.minutes = 0;
        this.hours = 0;
        this.laps = []; // Limpa a lista de voltas
      },
      registerLap() {
        // Adiciona o tempo atual à lista de voltas
        this.laps.push(this.formattedTime);
      },
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
  };
  </script>
  
  <style scoped>

@font-face {
    font-family: "clockicons";
    src: url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.eot");
    src: url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.eot?#iefix")format("embedded-opentype"),
    url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.woff2")format("woff2"),
    url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.woff")format("woff"),
    url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.ttf")format("truetype"),
    url("../../assets/fonts/clockicons/84cb021d5f9af287ffff84b61beef6dc.svg#clockicons")format("svg");
  font-weight: normal;
  font-style: normal;
}

  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cronometro-font {
    margin-bottom: 20px;
    font-family: 'clockicons', sans-serif;
  }
  

  .laps {
    margin-top: 20px;
    list-style-type: none;
    padding: 0;
  }
  
  .laps li {
    font-size: 18px;
    margin-bottom: 5px;
    
  }
  .lap-time{
    font-family: 'clockicons', sans-serif;
  }

  </style>
  