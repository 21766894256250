<template>
     <div class="container">
      <h1 class="mb-4">Politica de Privacidade</h1>
    </div>
  </template>
  
  <script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';

  export default {
    name: 'PoliticaDePrivacidade',
    mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('politica-de-privacidade',encryptTimestamp);
    },
  };
  </script>
  