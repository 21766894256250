export async function updateCounter(page, token) {
    try {
      const response = await fetch(`/api/contador?page=${page}&token=${token}`, {
        method: 'GET',
      });
      const result = await response.text();
      console.log(result);
    } catch (error) {
      console.error('Erro ao atualizar o contador:', error);
    }
  }