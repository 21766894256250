<template>
  <div class="container">
    <h1 class="mb-3">Bem-vindo à Página Home</h1>
    <h5 class="mb-4">
      Seu hub de ferramentas online para facilitar o dia a dia.
      No Webtools, você encontra uma variedade de recursos úteis que vão desde ferramentas de texto, como editores e cortadores de conteúdo, até calculadoras matemáticas avançadas e utilitários de computação.
      Seja para agilizar uma tarefa, resolver um problema ou simplificar seu trabalho, temos a ferramenta certa para você.
      Explore nossas funcionalidades e descubra como podemos tornar suas atividades mais rápidas e eficientes.</h5>
  

    <div class="row">
        <div v-for="ferramenta in ferramentasList" class="col-lg-4 mb-2">
            <div class="card mx-auto" >
                <div class="card-body">
                    <h5 class="card-title">{{ ferramenta.nome  }}</h5>
                    <p class="card-text">{{ ferramenta.descricao }}</p>
                    <a :href=ferramenta.link class="btn btn-primary btn-sm">Acessar</a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import ferramentas from '@/assets/data/ferramentas.js'
import { encryptString, decryptString } from '@/utils/cryptoUtils';

import { updateCounter } from '@/utils/methods.js';
export default {
  name: "Home",
  data(){
    return{
        ferramentasList: [],
    }
  },
  created(){
    this.ferramentasList = this.shuffleArray(ferramentas.categoria.flatMap(cat => cat.ferramentas).sort((a, b) => a.ordem - b.ordem));
    
    console.log(this.ferramentasList);
  },
  methods:{
    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
  },
  mounted() {
    const timestamp = new Date().getTime().toString();
    const encryptTimestamp = encryptString(timestamp);
    updateCounter('home', encryptTimestamp);
  },
}
</script>


<style scoped>


</style>
