<template>
    <div class="container">
      <h1 class="mb-4">Informações sobre a Tela</h1>
      <ul class="list-group">
        <li class="list-group-item">
          <strong>Resolução da Tela:</strong> {{ screenWidth }}x{{ screenHeight }} pixels
        </li>
        <li class="list-group-item">
          <strong>Tamanho da Janela:</strong> {{ windowWidth }}x{{ windowHeight }} pixels
        </li>
        <li class="list-group-item">
          <strong>Densidade de Pixels:</strong> {{ pixelDensity }}x
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';
  
  export default {
    data() {
      return {
        screenWidth: 0,
        screenHeight: 0,
        windowWidth: 0,
        windowHeight: 0,
        pixelDensity: 1,
      };
    },
    mounted() {
      this.getScreenInfo();
      window.addEventListener('resize', this.getScreenInfo); // Atualiza as informações quando a janela é redimensionada

      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('minha-tela',encryptTimestamp);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.getScreenInfo); // Remove o listener ao destruir o componente
    },
    methods: {
      getScreenInfo() {
        this.screenWidth = window.screen.width;
        this.screenHeight = window.screen.height;
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
        this.pixelDensity = window.devicePixelRatio;
      }
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  </style>
  