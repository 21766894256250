<template>
  
  <div class="container">
    <h1>Buscar minha localização</h1>

    <p v-if="errorMessage">{{ errorMessage }}</p>

    <!-- Exibe a latitude, longitude e precisão -->
    <div v-if="latitude && longitude" class="mt-3">
      <ul class="list-group">
        <li class="list-group-item">
          <strong>Latitude:</strong> {{ latitude }}
        </li>
        <li class="list-group-item">
          <strong>Longitude:</strong> {{ longitude }}
        </li>
        <li class="list-group-item">
          <strong>Precisão:</strong> {{ accuracy ? Math.ceil(accuracy) + ' metros' : 'Não disponível' }}
        </li>
      </ul>

    </div>

    <div id="map" style="height: 400px;"></div>

  </div>
</template>

<script>
import L from 'leaflet';
import "leaflet/dist/leaflet.css";


export default {
  data() {
    return {
      latitude: null,
      longitude: null,
      accuracy: null,
      errorMessage: null,
      map: null,
      marker: null,
    };
  },
  methods: {
    initMap(lat, lng) {
      // Cria o mapa no div com id "map"
      this.map = L.map('map').setView([lat, lng], 13);

      // Adiciona o tile layer do OpenStreetMap
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);

      // Adiciona um marcador no local do usuário
      this.marker = L.marker([lat, lng]).addTo(this.map)
        //.bindPopup('Você está aqui!')
        .openPopup();

      // Corrige o problema de redimensionamento do mapa
      this.$nextTick(() => {
        this.map.invalidateSize();
      });
    },
    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.accuracy = position.coords.accuracy;

            this.initMap(this.latitude, this.longitude);
          },
          (error) => {
            this.errorMessage = 'Erro ao acessar a localização: ' + error.message;
          },
          { enableHighAccuracy: true }
        );
      } else {
        this.errorMessage = 'Geolocalização não é suportada pelo seu navegador.';
      }
    },
  },
  mounted() {
    this.getUserLocation();
  }
};
</script>

<style scoped>

#map {
  margin-top: 20px; /* Para adicionar algum espaçamento do topo */
}

.container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}

.map-image {
  width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 8px;
}

p {
  margin-top: 10px;
}
</style>
