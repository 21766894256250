<template>
    <div class="container">
      <h1 class="mb-4">Dividir Texto</h1>
      
      <div class="mb-3">
        <label for="inputTexto" class="form-label">Digite o texto:</label>
        <textarea v-model="texto" id="inputTexto" rows="5" class="form-control"></textarea>
      </div>
  
      <div class="mb-3">
        <label for="palavraDivisao" class="form-label">Digite a palavra para dividir o texto:</label>
        <input v-model="palavraDivisao" id="palavraDivisao" type="text" class="form-control" />
      </div>
  
      <button @click="dividirTexto" class="btn btn-primary mb-3">Dividir o Texto</button>
  
      <div class="mb-3">
        <label for="outputTexto" class="form-label">Resultado:</label>
        <textarea v-model="textoDividido" id="outputTexto" rows="5" class="form-control" readonly></textarea>
      </div>
    </div>
  </template>
  
  <script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';

  export default {
    data() {
      return {
        texto: '',
        palavraDivisao: '',
        textoDividido: ''
      };
    },

    mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('dividir-texto',encryptTimestamp);
    },
    methods: {
      dividirTexto() {
        if (this.palavraDivisao) {
          this.textoDividido = this.texto.split(this.palavraDivisao).join('\n');
        } else {
          this.textoDividido = this.texto;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  </style>
  