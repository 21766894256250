<template>
    <div class="container">
      <h1 class="mt-4">Teste dos Botões do Mouse</h1>
      <p>Clique em qualquer botão do mouse ou role o scroll para ver o feedback.</p>
  
      <!-- Área de visualização do mouse -->
      <div class="mouse-visual mt-4">
        <div class="mouse-body">
          <!-- Botão esquerdo -->
          <div
            class="mouse-button left-button"
            :class="{ 'active': clickedButton === 'left' }"
          ></div>
          <!-- Botão direito -->
          <div
            class="mouse-button right-button"
            :class="{ 'active': clickedButton === 'right' }"
          ></div>
          <!-- Scroll -->
          <div
            class="mouse-button scroll-button-up"
            :class="{ 'active': isScrollingUp }"
          ></div>
            <!-- Scroll -->
            <div
            class="mouse-button scroll-button-baixo"
            :class="{ 'active': isScrollingDown }"
          ></div>
        </div>
      </div>
  
      <!-- Mostra qual botão foi clicado -->

      <div class="mt-3">
        <h3>Ação:</h3>
        <h3 v-if="clickedButton">{{ clickedButtonText }}</h3>
        <h3 v-else>{{ scrollDirection }}</h3>
      </div>

    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        clickedButton: null, // Guarda o botão clicado (left, right ou middle)
        isScrollingUp: false, // Indica se o scroll está sendo rolado
        isScrollingDown: false, // Indica se o scroll está sendo rolado
        scrollDirection: '', // Guarda a direção do scroll (up ou down)
      };
    },
    computed: {
      clickedButtonText() {
        if (this.clickedButton === 'left') return 'Botão Esquerdo';
        if (this.clickedButton === 'right') return 'Botão Direito';
        if (this.clickedButton === 'middle') return 'Scroll Clicado';
        return '';
      },
    },
    methods: {
      handleMouseClick(event) {
        this.isScrollingUp = this.isScrollingDown = false;
        if (event.button === 0) this.clickedButton = 'left';
        else if (event.button === 2) this.clickedButton = 'right';
        else if (event.button === 1){this.clickedButton = 'middle'; this.isScrollingDown=this.isScrollingUp=true;}
      },
      handleScroll(event) {
        this.clickedButton = null; // Limpa o estado de clique ao rolar o scroll
        this.isScrollingUp = event.deltaY < 0;
        this.isScrollingDown = event.deltaY > 0;
        this.scrollDirection = event.deltaY > 0 ? 'Scroll para Baixo' : 'Scroll para Cima';
      },
    },
    mounted() {
      window.addEventListener('mousedown', this.handleMouseClick);
      window.addEventListener('wheel', this.handleScroll);

      /*
        window.addEventListener('contextmenu', (event) => {
            event.preventDefault();
        });
        */

        /*
        window.addEventListener('wheel', (event) => {
            event.preventDefault();
        }, { passive: false });
        */

    },
    beforeUnmount() {
      window.removeEventListener('mousedown', this.handleMouseClick);
      window.removeEventListener('wheel', this.handleScroll);


    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .mouse-visual {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .mouse-body {
    width: 100px;
    height: 150px;
    background-color: #ddd;
    border-radius: 50px;
    position: relative;
  }
  
  .mouse-button {
    position: absolute;
    width: 40px;
    height: 60px;
    background-color: #ccc;
    top: 10px;
  }

  .left-button {
    left: 5px;
    border-top-left-radius:30px;
    border-top-right-radius:10px;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
  }
  
  .right-button {
    right: 5px;
    border-top-left-radius:10px;
    border-top-right-radius:30px;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
  }
  
  .scroll-button-up {
    top: 80px;
    left: 30px;
    width: 40px;
    height: 20px;
    background-color: #bbb;
    border-radius: 5px;
  }

  .scroll-button-baixo {
    top: 110px;
    left: 30px;
    width: 40px;
    height: 20px;
    background-color: #bbb;
    border-radius: 5px;
  }
  
  .mouse-button.active {
    background-color: #4caf50;
  }
  </style>
  