import { createRouter, createWebHistory } from 'vue-router';

// Importar os componentes que serão carregados nas rotas
import Home from '../views/Home.vue';
import PoliticaDePrivacidade from '../views/institucional/politica-de-privacidade.vue';
import TermosDeUso from '../views/institucional/termos-de-uso.vue';

import Computacao from '../views/computacao/index.vue';
import MeuIp from '../views/computacao/meu-ip.vue';
import MeuNavegador from '../views/computacao/meu-navegador.vue';
import MinhaTela from '../views/computacao/minha-tela.vue';
import MinhaLocalizacao from '../views/computacao/minha-localizacao.vue';
import ComputacaoHome from '../views/computacao/index.vue'
import Webcam from '../views/computacao/webcam.vue'
import Mouse from '../views/computacao/mouse.vue'
import Microfone from '../views/computacao/microfone.vue'
import Teclado from '../views/computacao/teclado.vue'

import String from '../views/string/index.vue'
import InverterTexto from '../views/string/inverter-texto.vue'
import DividirTexto from '../views/string/dividir-texto.vue'
import ContadorTexto from '../views/string/contador-texto.vue'
import CortarTexto from '../views/string/cortar-texto.vue'
import ContadorPalavra from '@/views/string/contador-palavra.vue';
import GeradorTexto from '@/views/string/gerador-texto.vue';
import GeradorSenha from '@/views/string/gerador-senha.vue';
import ConversorMaiusculasMinusculas from '@/views/string/conversor-maiusculas-minusculas.vue';

import GeradorHashMd5 from '@/views/criptografia/gerador-hash-md5.vue';
import GeradorHashSha1 from '@/views/criptografia/gerador-hash-sha1.vue';
import GeradorHashSha256 from '@/views/criptografia/gerador-hash-sha256.vue';
import GeradorHashSha224 from '@/views/criptografia/gerador-hash-sha224.vue';
import GeradorHashSha512 from '@/views/criptografia/gerador-hash-sha512.vue';


import Lotofacil from '@/views/loteria/lotofacil.vue';

import DataHora from '../views/data-hora/index.vue'
import Cronometro from '../views/data-hora/cronometro.vue'
import DiaHorario from '../views/data-hora/dia-horario.vue'



const routes = [
  { path: '/', component: Home, name: 'Home' },
  
  { path: '/politica-de-privacidade', component: PoliticaDePrivacidade, name: 'PoliticaDePrivacidade' },
  { path: '/termos-de-uso', component: TermosDeUso, name: 'TermosDeUso' },
  
  { path: '/computacao/', component: Computacao, name: 'Computacao' },
  { path: '/computacao/meu-ip', component: MeuIp, name: 'MeuIp' },
  { path: '/computacao/meu-navegador', component: MeuNavegador, name: 'MeuNavegador' },
  { path: '/computacao/minha-tela', component: MinhaTela, name: 'MinhaTela' },
  { path: '/computacao/minha-localizacao', component: MinhaLocalizacao, name: 'MinhaLocalizacao' },
  { path: '/computacao/webcam', component: Webcam, name: 'Webcam' },
  { path: '/computacao/microfone', component: Microfone, name: 'Microfone' },
  { path: '/computacao/mouse', component: Mouse, name: 'Mouse' },
  { path: '/computacao/teclado', component: Teclado, name: 'Teclado' },
  { path: '/computacao/', component: ComputacaoHome, name: 'ComputacaoHome' },

  { path: '/string/', component: String, name: 'String' },
  { path: '/string/inverter-texto', component: InverterTexto, name: 'InverterTexto' },
  { path: '/string/dividir-texto', component: DividirTexto, name: 'DividirTexto' },
  { path: '/string/contador-texto', component: ContadorTexto, name: 'ContadorTexto' },
  { path: '/string/cortar-texto', component: CortarTexto, name: 'CortarTexto' },
  { path: '/string/contador-palavra', component: ContadorPalavra, name: 'ContadorPalavra' },
  { path: '/string/gerador-texto', component: GeradorTexto, name: 'GeradorTexto' },
  { path: '/string/gerador-senha', component: GeradorSenha, name: 'GeradorSenha' },
  { path: '/string/conversor-maiusculas-minusculas', component: ConversorMaiusculasMinusculas, name: 'ConversorMaiusculasMinusculas' },

  { path: '/criptografia/gerador-hash-md5', component: GeradorHashMd5, name: 'GeradorHashMd5' },
  { path: '/criptografia/gerador-hash-sha1', component: GeradorHashSha1, name: 'GeradorHashSha1' },
  { path: '/criptografia/gerador-hash-sha256', component: GeradorHashSha256, name: 'GeradorHashSha256' },
  { path: '/criptografia/gerador-hash-sha224', component: GeradorHashSha224, name: 'GeradorHashSha224' },
  { path: '/criptografia/gerador-hash-sha512', component: GeradorHashSha512, name: 'GeradorHashSha512' },
  
  { path: '/loteria/lotofacil', component: Lotofacil, name: 'Lotofacil' },

  
  { path: '/data-hora/', component: DataHora, name: 'DataHora' },
  { path: '/data-hora/cronometro', component: Cronometro, name: 'Cronometro' },
  { path: '/data-hora/dia-horario', component: DiaHorario, name: 'DiaHorario' },
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Verifica se há uma posição salva (usada ao voltar para uma rota anterior)
    if (savedPosition) {
      return savedPosition
    } else {
      // Retorna para o topo da página
      return { top: 0 }
    }
  }
});

export default router;
