<template>
    <div class="container">
      <h1 class="mb-4">Análise de Texto</h1>
  
      <div class="mb-3">
        <label for="texto" class="form-label">Digite o texto:</label>
        <textarea v-model="texto" id="texto" rows="5" class="form-control"></textarea>
      </div>
  
      <div class="row">
        <div class="col-md-4">
          <p><strong>Caracteres:</strong> {{ contarCaracteres }}</p>
          <p><strong>Caracteres (sem espaços):</strong> {{ contarCaracteresSemEspacos }}</p>
          <p><strong>Palavras:</strong> {{ contarPalavras }}</p>
        </div>
        <div class="col-md-4">
          <p><strong>Espaços:</strong> {{ contarEspacos }}</p>
          <p><strong>Linhas:</strong> {{ contarLinhas }}</p>
        </div>
        <div class="col-md-4">
          <p><strong>Vogais:</strong> {{ contarVogais }}</p>
          <p><strong>Números:</strong> {{ contarNumeros }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { encryptString } from '@/utils/cryptoUtils';
  import { updateCounter } from '@/utils/methods.js';

  export default {
    data() {
      return {
        texto: ''
      };
    },
    mounted(){
      const timestamp = new Date().getTime().toString();
      const encryptTimestamp = encryptString(timestamp);
      updateCounter('contador-texto',encryptTimestamp);
    },
    computed: {
      contarCaracteres() {
        return this.texto.length;
      },
      contarCaracteresSemEspacos() {
        return this.texto.replace(/\s+/g, '').length;
      },
      contarPalavras() {
        return this.texto.trim().split(/\s+/).filter(word => word.length > 0).length;
      },
      contarEspacos() {
        return (this.texto.match(/\s/g) || []).length;
      },
      contarLinhas() {
        return this.texto.split(/\n/).length;
      },
      contarVogais() {
        return (this.texto.match(/[aeiouáéíóúàèìòùãõâêîôûäëïöü]/gi) || []).length;
      },
      contarNumeros() {
        return (this.texto.match(/\d/g) || []).length;
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  </style>
  